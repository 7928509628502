import http from "./Httpcommon";
import axios from "axios";

class ReservationService {
  baseURL = process.env.REACT_APP_BACK_END_BASE_URL;
  getResrevations(terrain) {
    return http.get("/reservations/all", {
      params: { terrain: terrain },
    });
  }
  getTerrain() {
    return http.get("/terrain", {});
  }

  getReservationsByDate(date, terrain) {
    return http.get("/reservations/all/ByDate", {
      params: { date: date, terrain_id: terrain },
    });
  }

  getCurrentUserReservationHistory(page = 1, limit = 10) {
    const token = JSON.parse(localStorage.getItem("token"))?.accessToken;
    return http.get("/reservations/current-user/history", {
      params: { page, limit },
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`, // Manually add the token
      },
    });
  }

  getCurrentUserReservationPlanning(page = 1, limit = 10) {
    const token = JSON.parse(localStorage.getItem("token"))?.accessToken;
    return http.get("/reservations/current-user/planning", {
      params: { page, limit },
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`, // Manually add the token
      },
    });
  }

  createResrevation(token, body) {
    return axios
      .create({
        baseURL: this.baseURL,
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .post("/reservations/create", body);
  }

  editResrevation(token, body) {
    return axios
      .create({
        baseURL: this.baseURL,
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .post("/reservations/add/player", body);
  }

  cancelReservation(token, body) {
    return axios
      .create({
        baseURL: this.baseURL,
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .post("/reservations/cancel", body);
  }

  cancelReservationBooking(token, body) {
    return axios
      .create({
        baseURL: this.baseURL,
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .post("/reservations/cancel/my-booking", body);
  }

  getReservationDateViewLimit() {
    const token = JSON.parse(localStorage.getItem("token"))?.accessToken;
    return http.get("/reservations/current-user/view", {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`, // Manually add the token
      },
    });
  }
}
export default new ReservationService();
