import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTopRender from "./Components/Shared/ScrollToTop_render";
import "./assets/css/plugins.css";
import "./assets/css/style.css";
import "./assets/css/skin-1.css";
import "./assets/css/templete.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Home from "./Components/Pages/Home/Home";
import Reservation from "./Components/Pages/Reservation/Reservation";
import Tournois from "./Components/Pages/Tournois/Tournois";
import Contact from "./Components/Pages/Contact/Contact";
import ErrorNotFound from "./Components/Pages/Error/Error";
import Login from "./Components/Pages/Login/Login";
import Register from "./Components/Pages/Register/Register";
import { useAddToHomescreenPrompt } from "./PWA/AddToHomeScreen";
import ProtectedRoutes from "./ProtectedRoutes";
import "react-toastify/dist/ReactToastify.css";
import { refreshUserUser } from "./Store/userSlice";
import { useDispatch } from "react-redux";
import TournamentEvent from "./Components/Pages/Tournois/TournamentEvent/TournamentEvent";
import axios from "axios";
import ForgotPass from "./Components/Pages/ForgotPass/ForgotPass";
import ResetPass from "./Components/Pages/ResetPass/ResetPass";
import InvalidToken from "./Components/Pages/ResetPass/InvalidToken";
import DocumentMeta from "react-document-meta"; // Import the DocumentMeta
import LoginProtectedRoutes from "./LoginProtectedRoutes";
import MyReservation from "./Components/Pages/MyReservation/MyReservation";

// Axios HTTP Request defaults
axios.defaults.baseURL = process.env.REACT_APP_BACK_END_BASE_URL;

function App() {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const dispatch = useDispatch();

  function getPWADisplayMode() {
    const isStandalone = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;
    if (document.referrer.startsWith("android-app://")) {
      setIsAppInstalled(true);
    } else if (navigator.standalone || isStandalone) {
      setIsAppInstalled(false);
    } else {
      setIsAppInstalled(true);
    }
  }

  useEffect(() => {
    getPWADisplayMode();
    let accessToken = localStorage.getItem("token");
    if (accessToken) {
      dispatch(refreshUserUser()).then((result) => {});
    }

    if (window.navigator.standalone) {
      setIsAppInstalled(true);
    }
  });

  const handleInstallClick = () => {
    if (prompt) {
      promptToInstall();
    } else {
      alert(
        'To install this app, tap the Share button and then "Add to Home Screen".'
      );
    }
  };

  const meta = {
    title: "Casa del Padel - Club de Padel à Sfax, Tunisie",
    description:
      "Découvrez Casa del Padel, votre club de padel à Sfax, Tunisie. Rejoignez-nous pour des matchs passionnants et des cours de padel adaptés à tous les niveaux.",
    meta: {
      charset: "utf-8",
      name: {
        keywords: "casadelpadel.tn, padel Sfax, padel Tunisie, padel",
      },
    },
    property: {
      "og:title": "Casa del Padel - Club de Padel à Sfax, Tunisie",
      "og:description":
        "Découvrez Casa del Padel, votre club de padel à Sfax, Tunisie. Rejoignez-nous pour des matchs passionnants et des cours de padel adaptés à tous les niveaux.",
      "og:url": "https://www.casadelpadel.tn",
    },
  };

  return (
    <DocumentMeta {...meta}>
      <div className="App">
        <Router>
          <ScrollToTopRender />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route element={<ProtectedRoutes />}>
              <Route exact path="reservation" element={<Reservation />} />
              <Route exact path="tournois" element={<Tournois />} />
              <Route
                exact
                path="tournois/event/:eventID"
                element={<TournamentEvent />}
              />
              <Route exact path="my-reservation" element={<MyReservation />} />
            </Route>
            <Route exact path="contact" element={<Contact />} />
            <Route element={<LoginProtectedRoutes />}>
              <Route exact path="login" element={<Login />} />
              <Route exact path="register" element={<Register />} />
              <Route exact path="forgot-pass" element={<ForgotPass />} />
              <Route exact path="reset-password" element={<ResetPass />} />
              <Route exact path="invalid-token" element={<InvalidToken />} />
            </Route>
            <Route path="*" element={<ErrorNotFound />} />
          </Routes>
        </Router>
      </div>
    </DocumentMeta>
  );
}

export default App;
