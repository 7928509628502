import React, { useState } from "react";
import "./MyReservation.css";
import Header from "../../layout/Header/Header";
import Footer from "../../layout/Footer/Footer";
import ScrollToTop from "../../Shared/ScrollToTop";
import { ToastContainer } from "react-toastify";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PlanningReservations from "./widgets/PlanningReservations";
import HistoricReservations from "./widgets/HistoricReservations";

const MyReservation = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Header />
      <div className="reservations_page_header">
        <h1 className="title title--h1 first-title title__separate">
          Mes réservations
        </h1>
      </div>

      {/* Tabs Section */}
      <Box sx={{ width: "100%", marginTop: "20px", padding: "0 20px" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          centered
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab label="Planning" />
          <Tab label="Historique" />
        </Tabs>
        <Box sx={{ marginTop: "20px" }}>
          {tabIndex === 0 && <PlanningReservations />}
          {tabIndex === 1 && <HistoricReservations />}
        </Box>
      </Box>

      <Footer hideContactInfo displayNewsLetter />
      <ScrollToTop />
      <ToastContainer position="top-right" theme="light" />
    </>
  );
};

export default MyReservation;
