import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import ReservationService from "../../../../api/ReservationService";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";

const PlanningReservations = () => {
  const [reservations, setReservations] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const itemsPerPage = 5;

  const fetchPlanningReservations = async () => {
    setLoading(true); // Start loading
    setError(""); // Clear any previous errors

    try {
      const response =
        await ReservationService.getCurrentUserReservationPlanning(
          page,
          itemsPerPage // Limit (items per page)
        );
      const { data, total } = response.data; // Extract data and total count
      setReservations(data);
      setTotalPages(Math.ceil(total / itemsPerPage)); // Update total pages
    } catch (error) {
      console.error("Error fetching planning reservations:", error);
      setError(
        "Une erreur s'est produite lors du chargement des réservations. Veuillez actualiser la page."
      );
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchPlanningReservations();
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h5" sx={{ mb: 3, fontWeight: "bold" }}>
        Planning des Réservations
      </Typography>

      {/* Error Message */}
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}
      {/* Show Spinner while loading */}
      {loading ? (
        <LinearProgress />
      ) : reservations.length === 0 ? (
        <div className="pas_de_tournois">
          <h1>🎾 Aucune réservation à venir ! </h1>
        </div>
      ) : (
        <>
          {/* Reservations List */}
          <Grid container spacing={3}>
            {reservations.map((reservation) => (
              <Grid item xs={12} sm={6} lg={4} key={reservation._id}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center", // Center content vertically
                    alignItems: "center", // Center content horizontally
                    height: "300px", // Ensure consistent height
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    overflow: "hidden", // Prevent content overflow
                    backgroundImage: `
            linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
            url(${require("../../../../assets/images/my-reservation-holder.jpg")})
          `, // Add a dark overlay using linear-gradient
                    backgroundSize: "cover", // Ensure the image covers the card
                    backgroundPosition: "center", // Center the image
                    backgroundRepeat: "no-repeat", // Prevent repetition
                  }}
                >
                  {/* Centered Text */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center", // Center text vertically
                      alignItems: "center", // Center text horizontally
                      color: "white", // White text for better contrast
                      textAlign: "center",
                      padding: "16px",
                    }}
                  >
                    <Typography
                      variant="h6" // Larger text for the date
                      sx={{
                        fontWeight: "bold",
                        marginBottom: "8px",
                        color: "white",
                      }}
                    >
                      {new Intl.DateTimeFormat("fr-FR", {
                        weekday: "long",
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      }).format(new Date(reservation.start.split("T")[0]))}
                    </Typography>
                    <Typography
                      variant="h4" // Even larger text for the time
                      sx={{
                        fontWeight: "bold",
                        marginBottom: "8px",
                        color: "white",
                      }}
                    >
                      {reservation.start.split("T")[1].substring(0, 5)} -{" "}
                      {reservation.end.split("T")[1].substring(0, 5)}
                    </Typography>
                    <Typography
                      variant="h6" // Slightly larger text for terrain
                      sx={{
                        marginBottom: "8px",
                        color: "white",
                        background: "rgb(170 166 166 / 61%)",
                        borderRadius: "45px",
                        padding: "4px 15px",
                      }}
                    >
                      Terrain: {reservation.terrain.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: reservation.reservationConfirmed
                          ? "lightgreen"
                          : "red",
                        fontWeight: "bold",
                        background: reservation.reservationConfirmed
                          ? "rgb(103 99 99 / 78%)"
                          : "rgb(234 232 232 / 78%)",
                        borderRadius: "45px",
                        padding: "4px 15px",
                      }}
                    >
                      {reservation.reservationConfirmed
                        ? "Confirmée"
                        : "Non Confirmée"}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* Pagination */}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Pagination
              count={totalPages} // Total number of pages
              page={page} // Current page
              onChange={handlePageChange} // Handle page changes
              color="primary" // Primary color styling
              siblingCount={1} // 1 page number around the current page
              boundaryCount={1} // 1 page number at the start and end
              showFirstButton // Show "First Page" button
              showLastButton // Show "Last Page" button
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default PlanningReservations;
